<template>
	<div>
		<!-- 头部logo -->
		<headLogo @navigation="navigation"></headLogo>
		
		<!-- 头部导航 -->
		<navigation :headTitle="headTitle" :headImg="headImg" style="margin-top:96px;"></navigation>
		
		<!-- 信息 -->
		<div class="intenationalOnePc">
			<el-row class="intenationalOnePc-box" v-if="notData">
				<span v-html="oneData.bodyContent" class="ql-editor" style="white-space: normal;">{{oneData.bodyContent}}</span>
				<el-image src="/index/across.png" class="acrossIntenPc-img"></el-image>
			</el-row>
			<el-row v-else>
				<el-row>
					<el-image src="/index/nodata.png" class="errorIntenPc-oneimg"></el-image>
				</el-row>
				<el-row class="errorIntenPc-onemessage">
					Empty data
				</el-row>
			</el-row>
			<!-- 切换 -->
			<div class="programsIntenPc-box">
				<div :class="[this.value==1?'forumIntenPc-yes':'forumIntenPc-no']" v-if="notForumData">
					<div @click="forum">
						INTERNATIONAL THEATRE FORUM
					</div>
				</div>
				<div :class="[this.value==2?'artsIntenPc-yes':'artsIntenPc-no']" v-if="notShipsData">
					<div @click="partnerships">
						INTERNATIONAL ALLIANCE
					</div>
				</div>
				<div :class="[this.value==3?'artsIntenPc-yes':'artsIntenPc-no']" v-if="notArtsData">
					<div @click="arts">
						STRATEGIC COLLABORATIONS
					</div>
				</div>
				<div :class="[this.value==4?'partnersIntenPc-yes':'partnersIntenPc-no']" v-if="slideshowImg.length">
					<div @click="partners">
						OUR PARTNERS
					</div>
				</div>
			</div>
			<!-- forum数据 -->
			<el-row id="forumScoll">
				<el-row v-if="notForumData">
					<el-row class="newsTwodata-databox">
						<el-col :span="12" v-for="(item,id) in ListData" :key="id">
							<div class="newsTwo-data" @click="details(item)">
								<el-row class="newsTwo-data-extend">
									<!-- forum图片 -->
									<img :src="item.pictureUrl" :onerror="onLoadImg(forumNotLoadImage)" class="newsTwodata-img"></img>
									<el-image class="newsTwodata-coverageimg" src="/home/newCoverage.png"></el-image>
									<p class="newsTwodata-logoimg"></p>
									<div class="newsTwodata-TitleMessage">
										<p class="newsTwodata-title">{{item.title}}</p>
										<p v-html="item.bodyContent" class="newsTwodata-message">{{item.bodyContent}}</p>
									</div>
									<p class="newsTwodata-date">
										<el-image class="newsTwodata-date-chainimg" src="/home/chain.png"></el-image>
									</p>
								</el-row>
							</div>
						</el-col>
					</el-row>
					<!-- 按钮 -->
					<el-row>
						<button v-if="loading" class="productionsIntenPc-btn" @click="load">Learn More</button>
					</el-row>
				</el-row>
				<!-- <el-row v-else>
					<el-row>
						<el-image src="/index/nodata.png" class="errorIntenPc-img"></el-image>
					</el-row>
					<el-row class="errorIntenPc-message">
						Empty data
					</el-row>
				</el-row> -->
			</el-row>
			<!-- partnerships数据 -->
			<el-row id="partnershipsScoll">
				<el-row v-if="notShipsData">
					<el-row class="newsTwodata-databox">
						<el-col :span="12" v-for="(item,id) in shipsListData" :key="id">
							<div class="newsTwo-data" @click="details(item)">
								<el-row class="newsTwo-data-extend">
									<!-- forum图片 -->
									<img :src="item.pictureUrl" :onerror="onLoadImg(forumNotLoadImage)" class="newsTwodata-img"></img>
									<el-image class="newsTwodata-coverageimg" src="/home/newCoverage.png"></el-image>
									<p class="newsTwodata-logoimg"></p>
									<div class="newsTwodata-TitleMessage">
										<p class="newsTwodata-title">{{item.title}}</p>
										<p v-html="item.bodyContent" class="newsTwodata-message">{{item.bodyContent}}</p>
									</div>
									<p class="newsTwodata-date">
										<el-image class="newsTwodata-date-chainimg" src="/home/chain.png"></el-image>
									</p>
								</el-row>
							</div>
						</el-col>
					</el-row>
					<!-- 按钮 -->
					<el-row>
						<button v-if="loading" class="productionsIntenPc-btn" @click="shipsLoad">Learn More</button>
					</el-row>
				</el-row>
				<!-- <el-row v-else>
					<el-row>
						<el-image src="/index/nodata.png" class="errorIntenPc-img"></el-image>
					</el-row>
					<el-row class="errorIntenPc-message">
						Empty data
					</el-row>
				</el-row> -->
			</el-row>
			<!-- arts数据 -->
			<el-row id="artsScoll">
				<el-row v-if="notArtsData">
					<el-row class="newsTwodata-databox">
						<el-col :span="12" v-for="(item,id) in polyListData" :key="id">
							<div class="newsTwo-data" @click="details(item)">
								<el-row class="newsTwo-data-extend">
									<!-- forum图片 -->
									<img :src="item.pictureUrl" :onerror="onLoadImg(artsNotLoadImage)" class="newsTwodata-img"></img>
									<el-image class="newsTwodata-coverageimg" src="/home/newCoverage.png"></el-image>
									<p class="newsTwodata-logoimg"></p>
									<div class="newsTwodata-TitleMessage">
										<p class="newsTwodata-title">{{item.title}}</p>
										<p v-html="item.bodyContent" class="newsTwodata-message">{{item.bodyContent}}</p>
									</div>
									<p class="newsTwodata-date">
										<el-image class="newsTwodata-date-chainimg" src="/home/chain.png"></el-image>
									</p>
								</el-row>
							</div>
						</el-col>
					</el-row>
					<!-- 按钮 -->
					<el-row>
						<button v-if="loading" class="productionsIntenPc-btn" @click="shipsLoad">Learn More</button>
					</el-row>
				</el-row>
				<!-- <el-row v-else>
					<el-row>
						<el-image src="/index/nodata.png" class="errorIntenPc-img"></el-image>
					</el-row>
					<el-row class="errorIntenPc-message">
						Empty data
					</el-row>
				</el-row> -->
			</el-row>
			<!-- logo数据 our partners -->
			<el-row id="ourScoll">
				<!-- logo -->
				<el-row class="logoIntenPc-imgbox" v-if="slideshowImg.length">
					<div v-for="(item,idx) in slideshowImg" class="logoIntenPc-boximg" :key="idx">
						<img :src="item" :onerror="onLoadImg(logoNotLoadImage)" class="logoIntenPc-img"/>
					</div>
				</el-row>
			</el-row>
		</div>
		
		<!-- 菜单导航 -->
		<meauDialog :meauValue="navigationvalue" @meauFn="menu"></meauDialog>
		
		<!-- 底部导航 -->
		<bottomNavigation></bottomNavigation>
	</div>
</template>

<script>
 
// 图片查询接口
import { selectPictureListById , selectContentListById } from '@/api/index.js'

	// 头部组件
	import headLogo from '@/components/headLogo.vue'
	// 头部导航
	import navigation from '@/components/navigation.vue'
	// 底部导航
	import bottomNavigation from '@/components/bottomNavigation.vue'
	// 菜单导航
	import meauDialog from '@/components/meauDialog.vue'
	export default
	{
		data()
		{
			return{
				// 头部导航标题
				headTitle:'INTERNATIONAL COLLABORATIONS',
				// 头部导航图片
				headImg:'/index/international.png',
				value:1,
				// 导航值
				navigationvalue:false,
				
				// 默认显示条数
				count:4,
				// 加载按钮
				loading:true,
				
				// 默认显示条数
				polyCount:2,
				// 加载按钮
				polyloading:true,
				
				shipsCount:4,
				shipsloading:true,
				
				oneData:{},
				newList:[],
				polyList:[],
				shipsList:[],
				slideshowImg:[],
				
				// 第一条消息
				notData:true,
				notForumData:true,
				notArtsData:true,
				notShipsData:true,
				
				forumNotLoadImage: "/PCimage/570_428.jpg", //forum未加载出来
				artsNotLoadImage: "/PCimage/1100_480.jpg", //arts未加载出来
				logoNotLoadImage: "/PCimage/270_270.jpg", //品牌未加载出来
			}
		},
		components:
		{
			headLogo,
			navigation,
			bottomNavigation,
			meauDialog
		},
		computed:
		{
			// 计算属性
			ListData()
			{
				let data=[];
				if(this.newList.length>4)
				{
					data=this.newList.slice(0,this.count)
					return data;
				}
				else
				{
					data=this.newList;
					this.loading=false;
					return data;
				}
			},
			polyListData()
			{
				let data=[];
				if(this.polyList.length>2)
				{
					data=this.polyList.slice(0,this.polyCount)
					return data;
				}
				else
				{
					data=this.polyList;
					this.polyloading=false;
					return data;
				}
			},
			shipsListData()
			{
				let data=[];
				if(this.shipsList.length>4)
				{
					data=this.shipsList.slice(0,this.shipsCount)
					return data;
				}
				else
				{
					data=this.shipsList;
					this.shipsloading=false;
					return data;
				}
			}
		},
		created()
		{
			this.oneList()// 获取第一条数据
			this.imgList()// 获取合作品牌图片
			this.getForumList();// 获取FORUM数据
			this.getArtsList();// 获取Arts数据
			this.getPartnershipsList(); //获取Partnerships数据
		},
		mounted()
		{
			window.addEventListener('scroll', this.scrollEvent);
		},
		// deactivated()
		// {
		// 	window.removeEventListener('scroll', this.scrollEvent);
		// },
		methods:
		{
			// 头部传来的数据
			navigation(v)
			{
				this.navigationvalue=v;
				console.log(this.navigationvalue,'头部传递菜单导航')
			},
			// 遮罩层传回来的数据
			menu(v)
			{
				this.navigationvalue=v;
			},
			
			// 获取第一条数据
			oneList()
			{
				selectContentListById(
				{
				  chnanelType: 1,
				  isIndex: 0,
				  moduleType: 'MODULE_INTERNATIONAL'
				})
				.then((response)=>
				{
					if(response.data.data=='')
					{
						this.notData=false;
					}
					else
					{
						this.notData=true;
						this.oneData=response.data.data[0];
					}
				})
			},
			// 获取合作品牌图片
			imgList()
			{
				selectPictureListById({chnanelType:1,picturetype:5})
				.then((response)=>
				{
					response.data.data.map((v)=>
					{
						this.slideshowImg.push(v.pictureUrl);
					})
				})
			},
			// 获取FORUM数据
			getForumList()
			{
				selectContentListById(
				{
				  chnanelType: 1,
				  isIndex: 0,
				  moduleType: 'MODULE_FORUM'
				})
				.then((response)=>
				{
					if(response.data.data=='')
					{
						this.notForumData=false;
					}
					else
					{
						this.notForumData=true;
						this.loading=true;
						this.newList=response.data.data;
					}
				})
			},
			// 获取Arts数据
			getArtsList()
			{
				selectContentListById(
				{
				  chnanelType: 1,
				  isIndex: 0,
				  moduleType: 'MODULE_ARTS'
				})
				.then((response)=>
				{
					if(response.data.data=='')
					{
						this.notArtsData=false;
					}
					else
					{
						this.notArtsData=true;
						this.polyloading=true;
						this.polyList=response.data.data;
					}
				})
			},
			
			// 获取Partnerships数据
			getPartnershipsList()
			{
				selectContentListById(
				{
				  chnanelType: 1,
				  isIndex: 0,
				  moduleType: 'MODULE_PARTNERSHIPS'
				})
				.then((response)=>
				{
					if(response.data.data=='')
					{
						this.notShipsData=false;
					}
					else
					{
						this.notShipsData=true;
						this.shipsloading=true;
						this.shipsList=response.data.data;
					}
				})
			},
			
			// 点击事件
			forum()
			{
				this.value=1;
				var PageTd=document.querySelector('#forumScoll')
				window.scrollTo(
				{
					'top':PageTd.offsetTop,
					'behavior':'smooth'
				})
			},
			arts()
			{
				this.value=2;
				var PageTd=document.querySelector('#artsScoll')
				window.scrollTo(
				{
					'top':PageTd.offsetTop,
					'behavior':'smooth'
				})
			},
			partnerships()
			{
				this.value=3;
				var PageTd=document.querySelector('#partnershipsScoll')
				window.scrollTo(
				{
					'top':PageTd.offsetTop,
					'behavior':'smooth'
				})
			},
			partners()
			{
				this.value=4;
				var PageTd=document.querySelector('#ourScoll')
				window.scrollTo(
				{
					'top':PageTd.offsetTop,
					'behavior':'smooth'
				})
			},
			
			// 滑动事件
			scrollEvent()
			{
				// 获取视窗高度
				let domHight = document.body.offsetHeight;
				// dom滚动位置
				let scrollTop =
				  window.scrollY ||
				  document.documentElement.scrollTop ||
				  document.body.scrollTop;
				var PageTdForum=document.querySelector('#forumScoll')
				var PageTdArts=document.querySelector('#artsScoll')
				var PageTdpartnerships=document.querySelector('#partnershipsScoll')
				var PageTdOur=document.querySelector('#ourScoll')
				if(scrollTop>=PageTdForum.offsetTop)
				{
					this.value=1;
				}
				if(scrollTop>=PageTdArts.offsetTop)
				{
					this.value=2;
				}
				if(scrollTop>=PageTdpartnerships.offsetTop)
				{
					this.value=3;
				}
				if(scrollTop>=PageTdOur.offsetTop)
				{
					this.value=4;
				}
			},
			
			// 跳转详情
			details(item)
			{
				localStorage.setItem('Details',JSON.stringify(item));
				this.$router.push(
				{
					path:'/noticesDetails',
					query:
					{
						item:item,
						headTitle:'INTERNATIONAL COLLABORATIONS',
						headImg:'/index/international.png'
					}
				})
			},
			
			// 一级 - 加载 - 收起
			load()
			{
				this.count+=4;
				if(this.count>=this.newList.length)
				{
					this.loading=false
				}
			},
			// 二级 - 加载 - 收起
			polyLoad()
			{
				this.polyCount+=2;
				if(this.polyCount>=this.polyList.length)
				{
					this.polyloading=false
				}
			},
			// 二级 - 加载 - 收起
			shipsLoad()
			{
				this.shipsCount+=4;
				if(this.shipsCount>=this.shipsList.length)
				{
					this.shipsloading=false
				}
			},
			
			// 图片加载完成时触发
			onLoadImg(imgSrc) 
			{
				return 'this.src='+'"'+imgSrc+'";this.οnlοad=null';
			},
		}
	}
</script>

<style scoped lang="less">
	@import '../assets/TwoDataBox.css';
	@import '../assets/production.css';
	.intenationalOnePc{
		width: 1170px;
		margin: 0 auto;
	}
	.intenationalOnePc-box{
		width: 100%;
		background-color: #F7F4F8;
		border-radius: 20px;
		padding: 60px 40px 46px;
		position: relative;
		line-height: 40px;
		text-align: left;
	}
	.intenationalOnePc-box img{
		max-width: 100%;
	}
	.polyDataBoxIntenPc{
		width:100%;
	}
	.acrossIntenPc-img{
		width: 170px;
		height: 6px;
		position: absolute;
		top: 40px;
		left: -31px;
	}
	.programsIntenPc-box{
		width: 100%;
		border: solid 2px #ccc;
		font-size: 12px;
		color: #505050;
		border-top-left-radius: 50px;
		border-bottom-left-radius: 50px;
		border-top-right-radius: 50px;
		border-bottom-right-radius: 50px;
		// line-height: 50px;
		margin-top: 40px;
		display: flex;
		&>div{
			flex: 1;
			padding: 10px 15px;
			display: flex;
			align-items: center;
			justify-content: center;
			height: auto!important;;
			&:last-of-type{
				border-top-right-radius: 50px;
				border-bottom-right-radius: 50px;
			}
		}
	}
	.forumIntenPc-yes{
		height: 46px;
		font-size: 14px;
		background-color: #FCD002;
		border-top-left-radius: 50px;
		border-bottom-left-radius: 50px;
	}
	.forumIntenPc-no{
		height: 46px;
		font-size: 14px;
		background-color: #F0EDF1;
		border-top-left-radius: 50px;
		border-bottom-left-radius: 50px;
	}
	.artsIntenPc-yes{
		height: 46px;
		padding-left: 30px;
		padding-right: 30px;
		font-size: 14px;
		background-color: #FCD002;
	}
	.artsIntenPc-no{
		height: 46px;
		padding-left: 30px;
		padding-right: 30px;
		font-size: 14px;
		background-color: #F0EDF1;
	}
	.partnersIntenPc-yes{
		height: 46px;
		font-size: 14px;
		background-color: #FCD002;
		border-top-right-radius: 50px;
		border-bottom-right-radius: 50px;
	}
	.partnersIntenPc-no{
		height: 46px;
		font-size: 14px;
		background-color: #F0EDF1;
		border-top-right-radius: 50px;
		border-bottom-right-radius: 50px;
	}
	
	.productionsIntenPc-btn{
		width: 400px;
		height: 50px;
		border: none;
		border-radius: 50px;
		margin-top: 30px;
		background-color: #FCD002;
		margin-bottom: 20px;
		font-size: 24px;
		eight: 400;
		color: #505050;
		line-height: 25px;
	}
	.productionsIntenPc-nobtn{
		width: 30%;
		height: 40px;
		border: none;
		border-radius: 50px;
		font-size: 15px;
		margin-top: 30px;
		background-color: #CCC;
		color: #505050;
		margin-bottom: 20px;
	}
	.logoIntenPc-boximg{
		width: 24%;
		height: 270px;
		margin-right: 1%;
		float: left;
		margin-top: 20px;
	}
	.logoIntenPc-img{
		width: 270px;
		height: 270px;
		margin-bottom: 25px;
		border-radius: 20px;
	}
	.errorIntenPc-img{
		width: 200px;
		height: 200px;
		margin-top: 100px;
	}
	.errorIntenPc-message{
		margin-top: 30px;
		font-size: 20px;
		color: #3B519D;
		margin-bottom: 50px;
		word-wrap:break-word;
		white-space: pre-wrap;
	}
	.errorIntenPc-oneimg{
		width: 200px;
		height: 150px;
	}
	.errorIntenPc-onemessage{
		margin-top: 10px;
		font-size: 20px;
		color: #3B519D;
	}
	
	.newsTwodata-databox{
		margin-bottom: 20px;
	}
</style>